.custom-title {
  font-weight: 500;
  line-height: 1.3;
  color: $main-green;
}
.custom-title-large {
  @extend .custom-title;
  font-size: 2rem;
}

.custom-title-secondary {
  @extend .custom-title;
  font-size: 1.5rem;
}

.custom-normal-text {
  color: #4a4e5c;
  font-weight: 400;
  font-size: 1rem;
}
.custom-body-text {
  @extend .custom-normal-text;
  font-size: 1.3rem;
}
.custom-medium-text {
  @extend .custom-normal-text;
  font-size: 1.5rem;
}
.custom-subtitle-text {
  @extend .custom-normal-text;
  font-size: 0.9rem;
}

// .text-warn {
//   color: #ef5350;
// }

.text-blue {
  line-height: 1.3;
  color: $main-blue;
}

.text-green {
  line-height: 1.3;
  color: $main-green;
}
.custom-text-link {
  font-weight: 600;
  color: $main-blue;
  font-size: 0.9rem;
}
