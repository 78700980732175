.card-border-grey {
  border: 1px solid $main-grey80 !important;
  box-shadow: none !important;
}

.remove-borde-background {
  background-color: transparent;
  box-shadow: none !important;
  margin: 0;
  padding: 0;
}
