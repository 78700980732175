.custom-btn {
  &:hover {
    border: 1px solid #ffffff;
    transition: 0.2s all;
    transition-delay: 0.2s;
  }
  border: 1px solid $main-green;
  font-weight: bold;
  text-transform: capitalize;
  box-shadow: none !important;
}

.custom-btn-green {
  @extend .custom-btn;
  color: $main-green;
  background-color: $main-green-lighten;
}

.custom-btn-green-inverse {
  @extend .custom-btn;
  background-color: #ffffff;
  border: 1px solid $main-green;
  color: $main-green;
}

.custom-btn-blue {
  @extend .custom-btn;
  background-color: $main-blue;
  border: 1px solid $main-blue;
  color: #ffffff;
}

.custom-btn-blue-inverse {
  @extend .custom-btn;
  background-color: #ffffff;
  border: 1px solid $main-blue;
  color: $main-blue;
}

.btn-large {
  padding: 1.2rem 2.5rem 1.2rem 2.5rem;
  font-size: 1.1rem;
}
