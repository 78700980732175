// // CONTENT
// $color-body-bg: #f9f7f5;
// $color-text: #444;
// $color-heading-text: #35495e;

// // LINKS
// $color-link-text: #39a275;
// $color-link-text-active: $color-text;

// // INPUTS
// $color-input-border: lighten($color-heading-text, 50%);

// // BUTTONS
// $color-button-bg: $color-link-text;
// $color-button-disabled-bg: darken(desaturate($color-button-bg, 20%), 10%);
// $color-button-text: white;

$main-green: #008045;
$main-green-lighten: #ddfe54;
$main-blue: #0080d1;
$main-grey: #f8f8f8;
$main-grey80: #cccccc;
$main-warn: #ef5350;
